





































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { getNameByEmail } from '@/utils/users';
import accountModule from '@/store/modules/accountModule';
import { clearSession } from '@/lib/auth';
import { getComponent } from '@/utils/helpers';
import { SHOW_ALERT, SHOW_ENDPOINT_RESPONSE_ALERT } from '@/lib/constants';
import workflowModule from '@/store/modules/workflowModule';
import {
  preloadData,
} from '@/utils/preloadOfflineData';
import tasqsListModule from '@/store/modules/tasqsListModule';
import metaDataModule from '@/store/modules/metaDataModule';

@Component({
  components: {
    AppSidebar: () => getComponent('common/AppSidebar'),
    AppFooter: () => getComponent('common/AppFooter'),
  },
})
export default class AppLayout extends Vue {
  isSidebarClosed = false;

  notificationsOpen = false;

  open = false;

  right = false;

  settingsOpen = false;

  showModal = false;

  showAlert = false;

  windowWidth = window.innerWidth;

  alertText = '';

  showEndpointResponseMsg = false;

  isErrorInResponseMsg = false;

  endpointResponseMsg = ''

  userSettingsPageLink = {
    name: 'UserSettings',
    to: 'UserSettings',
  }

  selectedBoardingItem: any = null;

  onBoardingItems = [
    {
      title: 'Getting started',
      subTitle: 'Explore key features and possibilities.',
      icon: 'onboarding.png',
      type: 'GettingStarted',
      external: true,
      src: 'https://tasq.io/updates',
    },
    {
      title: "What's New",
      subTitle: 'Find out latest product changes.',
      icon: 'whatsnew.png',
      type: 'New',
      external: true,
      src: 'https://tasq.io/updates',
    },
    {
      title: 'Get Help',
      subTitle: "Need assistance? We're here to help.",
      icon: 'gethelp.png',
      type: 'Help',
      external: true,
      src: 'https://tasq.io/updates',
    },
  ]

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async beforeMount() {
    // await localForage.clear();
    // await localForageGlobal.clear();
    await workflowModule.getUserProfile({ username: accountModule.user.email.toLowerCase(), useExisting: true });
    // console.log(workflowModule.user);
    if (window.location.pathname === '/') {
      if (workflowModule.user.defaultPage && workflowModule.user.defaultPage.includes('kanban')) {
        this.$router.push({ name: 'TasqsKanban' });
      } else {
        this.$router.push({ name: 'Tasqs' });
      }
    }
  }

  onResize() {
    this.windowWidth = window.innerWidth;
  }

  get isMobile() {
    return this.windowWidth < 769;
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  //  window.addEventListener('online', this.onlineMode);
  //  window.addEventListener('offline', this.offlineMode);
  }

  async setWaitingOnOptions() {
    await metaDataModule.getDatalist();
    const requiredDataList = metaDataModule.requiredDatalist;
    const formCategoriesItem = requiredDataList.find((list) => list.title === 'Waiting On');
    // const formArtificialLiftItem = requiredDataList.find((list) => list.title === 'Artificial Lift Types');
    if (formCategoriesItem) {
      const formCategoriesPayload = await metaDataModule.getDatalist(formCategoriesItem.id);
      if (formCategoriesPayload && formCategoriesPayload.length) {
        const waitingOnOptions = formCategoriesPayload[0].ListItems.filter((field) => field.Active).map((item) => item.Name).sort();
        if (waitingOnOptions) {
          metaDataModule.setWaitingOnList(waitingOnOptions);
        }
      }
    }
  }

  async created() {
    this.$eventBus.$on(SHOW_ALERT, (text) => {
      this.showEndpointResponseMsg = false;
      this.showAlert = true;
      this.alertText = text;
      this.timeAlert(4000);
    });


    this.$eventBus.$on(SHOW_ENDPOINT_RESPONSE_ALERT, (payload) => {
      this.showAlert = false;
      this.showEndpointResponseMsg = true;
      this.endpointResponseMsg = payload.text;
      this.isErrorInResponseMsg = payload.error;
      this.timmerForEndpointResponse(8000);
    });

    await this.setWaitingOnOptions();

    if (navigator.onLine) {
      if (!accountModule.didPreloadData) {
        // console.log('preloading');
			//  await preloadData();
      }
    }
  }

  get isOnlineApp() {
    return navigator.onLine;
  }

  showBoardingModal(item) {
    this.selectedBoardingItem = item;
    this.showModal = true;
  }

  closeFilterDialog() {
    this.settingsOpen = false;
  }

  toggle() {
    this.open = !this.open;
  }

  async timeAlert(time) {
	  await this.sleep(time);
    this.showAlert = false;
    this.alertText = '';
  }


  async timmerForEndpointResponse(time) {
	  await this.sleep(time);
    this.showEndpointResponseMsg = false;
    this.isErrorInResponseMsg = false;
    this.endpointResponseMsg = ''
  }

  handleSidebarItemClick(link) {
    this.$router.push({
      name: link.to,
    });
  }

  logout() {
    clearSession();
    this.$router.push({ name: 'Login' });
  }

  onSideBarClose() {
    this.isSidebarClosed = true;
  }

  toggleSidebar() {
    // @ts-ignore
    // eslint-disable-next-line no-unused-expressions
    this.$refs.sidebar.toggleSidebarMenu();
    this.isSidebarClosed = false;
  }
}
